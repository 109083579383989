@import "../src/stylesheets/fonts.scss";
@import "../src/stylesheets/fontFamily.scss";
@import "../src/stylesheets/variables.scss";
@import "../src/stylesheets/commonPopUp.scss";

html * div,
body,
*,
body *:before,
body *:after { box-sizing: border-box; }
strong, b { font-family: $mediumweight; font-weight: normal;}

body {margin: 0; padding: 0; background: #fff; width: 100%; min-height: 100vh; font-family: $normalweight;
    font-size:14px; font-weight: 400; box-sizing: border-box; -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; overflow-x: hidden; color: $normalcolor;}
h1, h2, h3, h4, h5, h6 {font-weight: normal;}
ul, li {margin: 0; padding: 0; list-style-type: none;}

.pageLoaderBox { position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 99999999; }

.pageLoaderBox:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 104px;
  height: 104px;
  box-shadow: 1px 4px 40px rgba(67, 117, 195, 0.2);
  background: #fff;
  padding: 20px;
  transform: translate(-50%, -50%);
  z-index: 99;
  border-radius: 6px;
}

.pageLoaderBox .pageLoader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;
  background: url("assets/images/sprite.png") 0 -174px no-repeat;
  animation: homeloadinginner 1.55s ease-in-out infinite;
  z-index: 99;
}

.pageLoaderBox .pageLoader img {
  width: 100%;
  height: auto;
}

.pageLoaderBox .loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}

@-webkit-keyframes homeloadinginner {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes homeloadinginner {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

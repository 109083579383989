@charset "UTF-8";
@import "./fontFamily.scss";

@font-face {
  font-family: "engineer";
  src: url("../assets/fonts/engineer.eot");
  src: url("../assets/fonts/engineer.eot?#iefix") format("embedded-opentype"),
  url($engineerwoff) format("woff"),
  url($engineerttf) format("truetype"),
  url("../assets/fonts/engineer.svg#engineer") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: "engineer", Georgia, serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "engineer", Georgia, serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tick:before {
  content: "\61";
}

.icon-doller:before {
  content: "\62";
}

.icon-cancel:before {
  content: "\63";
}

.icon-edit:before {
  content: "\64";
}

.icon-phone-receiver:before {
  content: "\65";
}

.icon-hamicon:before {
  content: "\66";
}

.icon-question-mark:before {
  content: "\67";
}

.icon-right:before {
  content: "\68";
}

.icon-facebook:before {
  content: "\69";
}

.icon-google-plus:before {
  content: "\6a";
}

.icon-twitter:before {
  content: "\6b";
}

.icon-download:before {
  content: "\6c";
}

.icon-search:before {
  content: "\6d";
}

.icon-view:before {
  content: "\6e";
}

.icon-plus:before {
  content: "\6f";
}

.icon-cards:before {
  content: "\70";
}

.icon-dashboard:before {
  content: "\71";
}

.icon-logout:before {
  content: "\72";
}

.icon-profile:before {
  content: "\73";
}

.icon-setting:before {
  content: "\74";
}

.icon-breadcrum:before {
  content: "\75";
}

.icon-files:before {
  content: "\76";
}

.icon-archived:before {
  content: "\77";
}

.icon-dashboard-1:before {
  content: "\78";
}

.icon-finished:before {
  content: "\79";
}

.icon-offers:before {
  content: "\7a";
}

.icon-queued:before {
  content: "\41";
}

.icon-running:before {
  content: "\42";
}

.icon-settings:before {
  content: "\43";
}

.icon-share:before {
  content: "\44";
}

.icon-more:before {
  content: "\45";
}

.icon-linkedin:before {
  content: "\46";
}

.icon-pricenewproject:before {
  content: "\47";
}

.icon-drag:before {
  content: "\48";
}

.icon-next:before {
  content: "\49";
}

.icon-prev:before {
  content: "\4a";
}

.icon-arrows-ccw:before {
  content: "\4b";
}

.icon-info:before {
  content: "\4c";
}

.icon-drag-1:before {
  content: "\4d";
}

.icon-feature:before {
  content: "\4e";
}

.icon-platform:before {
  content: "\4f";
}

.icon-template:before {
  content: "\50";
}

.icon-team:before {
  content: "\51";
}

.icon-speed:before {
  content: "\52";
}

.icon-group:before {
  content: "\53";
}

.icon-paperclip:before {
  content: "\54";
}

.icon-tick-inside:before {
  content: "\55";
}

.icon-invite:before {
  content: "\56";
}

.icon-drag-2:before {
  content: "\57";
}

.icon-sort:before {
  content: "\58";
}

.icon-dashboard-2:before {
  content: "\59";
}

.icon-invoice:before {
  content: "\5a";
}

.icon-reports:before {
  content: "\30";
}

.icon-managedevelopers:before {
  content: "\31";
}

.icon-myaccount:before {
  content: "\32";
}

.icon-needhelp:before {
  content: "\33";
}

.icon-noticeboard:before {
  content: "\34";
}

.icon-uploaddoc:before {
  content: "\35";
}

.icon-deletedoc:before {
  content: "\36";
}

.icon-downloaddoc:before {
  content: "\37";
}

.icon-star:before {
  content: "\38";
}
.icon-exclamation-triangle:before {
  content: "\39";
}
.icon-clock:before {
  content: "\21";
}
.icon-infonew:before {
  content: "\22";
}
.icon-crossnew:before {
  content: "\23";
}
.icon-uparrow:before {
  content: "\24";
}
.icon-downarrow:before {
  content: "\25";
}
.icon-expand:before {
  content: "\26";
}
.icon-collapse:before {
  content: "\27";
}
.icon-payrecieved:before {
  content: "\28";
}
.icon-paypending:before {
  content: "\29";
}
.icon-filter:before {
  content: "\2a";
}
.icon-assignment:before {
  content: "\2b";
}
.icon-mcq:before {
  content: "\2c";
}
.icon-email:before {
  content: "\2d";
}
.icon-reply:before {
  content: "\2e";
}
.icon-calendar-combined:before {
  content: "\2f";
}
.icon-attendancetracker:before {
  content: "\3a";
}
.icon-myprojects:before {
  content: "\3b";
}
.icon-tasktracker:before {
  content: "\3c";
}
.icon-leftarrow:before {
  content: "\3d";
}
.icon-rightarrow:before {
  content: "\3e";
}
.icon-magnifying:before {
  content: "\3f";
}
.icon-phonescreen:before {
  content: "\40";
}
.icon-maximize:before {
  content: "\5b";
}
.icon-minimize:before {
  content: "\5d";
}
.icon-webscreen:before {
  content: "\5e";
}
.icon-billing:before {
  content: "\5f";
}
.icon-designboard:before {
  content: "\60";
}
.icon-document:before {
  content: "\7b";
}
.icon-coderepo:before {
  content: "\7c";
}
.icon-ideaboard:before {
  content: "\7d";
}
.icon-home:before {
  content: "\7e";
}
.icon-left-arrow:before {
  content: "\5c";
}
.icon-todo:before {
  content: "\e000";
}
.icon-message:before {
  content: "\e001";
}
.icon-planner:before {
  content: "\e002";
}
.icon-settings-1:before {
  content: "\e003";
}
.icon-buildcard:before {
  content: "\e004";
}
.icon-floppy-o:before {
  content: "\e005";
}
.icon-budget:before {
  content: "\e006";
}
.icon-credit-card:before {
  content: "\e007";
}
.icon-money:before {
  content: "\e008";
}
.icon-faq:before {
  content: "\e009";
}
.icon-team-planner:before {
  content: "\e00a";
}
.icon-bell-o:before {
  content: "\e00b";
}
.icon-code:before {
  content: "\e00c";
}
.icon-quora:before {
  content: "\e00d";
}
.icon-builder-icon:before {
  content: "\e00e";
}
.icon-builder-logo:before {
  content: "\e00f";
}
.icon-spinner:before {
  content: "\e010";
}
.icon-shopping-cart:before {
  content: "\e011";
}
.icon-whatsapp:before {
  content: "\e012";
}
.icon-transfer:before {
  content: "\e013";
}
.icon-unlink:before {
  content: "\e014";
}
.icon-transfer-1:before {
  content: "\e015";
}
.icon-bell:before {
  content: "\e017";
}
.icon-documents1:before {
  content: "\e018";
}
.icon-invoice1:before {
  content: "\e019";
}
.icon-office1:before {
  content: "\e01a";
}
.icon-account-new:before {
  content: "\e01b";
}
.icon-contract-new:before {
  content: "\e016";
}
.icon-error-new:before {
  content: "\e01c";
}
.icon-invoice-new:before {
  content: "\e01d";
}
.icon-neutralface-new:before {
  content: "\e01e";
}
.icon-add-user:before {
  content: "\e020";
}
.icon-racing-new:before {
  content: "\e021";
}
.icon-checkmarknew:before {
  content: "\e01f";
}
.icon-heart-o:before {
  content: "\e022";
}
.icon-linkedin-square:before {
  content: "\e023";
}
.icon-envelope:before {
  content: "\e024";
}
.icon-filtercheck:before {
  content: "\e025";
}
.icon-funnel:before {
  content: "\e026";
}
.icon-nexticon:before {
  content: "\e027";
}
.icon-download-1:before {
  content: "\e028";
}
.icon-share-3:before {
  content: "\45";
}
.icon-transfer-2:before {
  content: "\5a";
}
.icon-invite-1:before {
  content: "\32";
}
.icon-invoice:before {
  content: "\e008";
}
.icon-invoice1:before {
  content: "\e019";
}
.icon-myaccount:before {
  content: "\e029";
}
.icon-more:before {
  content: "\e02a";
}
.icon-money:before {
  content: "\e02b";
}
.icon-wrench:before {
  content: "\e02c";
}
.icon-cog:before {
  content: "\e02d";
}
.icon-icon-panding:before {
  content: "\e02e";
}
.icon-thumbs-up:before {
  content: "\e02f";
}
.icon-lock:before {
  content: "\e030";
}
.icon-infonew1:before {
  content: "\e031";
}
.icon-calendar:before {
  content: "\e033";
}
.icon-menu:before {
  content: "\e032";
}
.icon-noun-timer:before {
  content: "\e034";
}
.icon-wallet:before {
  content: "\e035";
}
.icon-minus:before {
  content: "\e036";
}
.icon-stopwatch:before {
  content: "\e037";
}
.icon-cw:before {
  content: "\e038";
}
.icon-prototype:before {
  content: "\e039";
}
.icon-link:before {
  content: "\e03a";
}
.icon-comments:before {
  content: "\e03b";
}
.icon-share-outline:before {
  content: "\e03c";
}
.icon-edit-1:before {
  content: "\e03d";
}
.icon-nextarrow:before {
  content: "\e03e";
}
.icon-external-link:before {
  content: "\e03f";
}
.icon-android-options:before {
  content: "\e040";
}
.icon-newlogin:before {
  content: "\e042";
}
.icon-newregister:before {
  content: "\e043";
}
.icon-newexpert:before {
  content: "\e041";
}
.icon-newsales:before {
  content: "\e044";
}
.icon-close:before {
  content: "\e045";
}
.icon-noun-timer-1:before {
  content: "\e046";
}
.icon-iconcross:before {
  content: "\e047";
}
.icon-icondownload:before {
  content: "\e048";
}
.icon-iconedit:before {
  content: "\e049";
}
.icon-iconinvite:before {
  content: "\e04a";
}
.icon-iconlocation:before {
  content: "\e04b";
}
.icon-iconoptions:before {
  content: "\e04c";
}
.icon-iconproducttype:before {
  content: "\e04d";
}
.icon-iconprojectname:before {
  content: "\e04e";
}
.icon-iconshare:before {
  content: "\e04f";
}
.icon-icontransfer:before {
  content: "\e050";
}
.icon-doublearrow:before {
  content: "\e051";
}
.icon-play:before {
  content: "\e052";
}
.icon-arrownew:before {
  content: "\e053";
}
.icon-settings-streamline-1:before {
  content: "\e054";
}
.icon-logout-1:before {
  content: "\e055";
}
.icon-dashboard-3:before {
  content: "\e056";
}
.icon-newnext:before {
  content: "\e057";
}
.icon-creditcard:before {
  content: "\e058";
}
.icon-folder:before {
  content: "\e059";
}
.icon-in:before {
  content: "\e05a";
}
.icon-android-contract:before {
  content: "\e05b";
}
.icon-android-expand:before {
  content: "\e05c";
}
.icon-doublearrow:before {
  content: "\e051";
}
.icon-deletenew:before {
  content: "\e05d";
}
.icon-usernew:before {
  content: "\e05e";
}
.icon-ic-build-card:before {
  content: "\e05f";
}
.icon-ic-currency:before {
  content: "\e060";
}
.icon-ic-make:before {
  content: "\e061";
}
.icon-ic-name:before {
  content: "\e062";
}
.icon-ic-phases:before {
  content: "\e063";
}
.icon-ic-project-date:before {
  content: "\e064";
}
.icon-ic-project-info:before {
  content: "\e065";
}
.icon-ic-similar-apps:before {
  content: "\e066";
}
.icon-shape:before {
  content: "\e067";
}
.icon-customprototype:before {
  content: "\e035";
}
.icon-prototype-1:before {
  content: "\e068";
}
.icon-infoxh:before {
  content: "\e069";
}
.icon-write:before {
  content: "\e06a";
}
.icon-percent:before {
  content: "\e052";
}
.icon-fillter:before {
  content: "\e06b";
}
.icon-link-1:before {
  content: "\e06c";
}
.icon-delete:before {
  content: "\e06d";
}
.icon-eye:before {
  content: "\e06e";
}
.icon-leftside:before {
  content: "\e06f";
}
.icon-asking:before {
  content: "\e070";
}
.icon-info-circle:before {
  content: "\e071";
}
.icon-home-1:before {
  content: "\e072";
}
.icon-recommended:before {
  content: "\e073";
}
.icon-question-circle:before {
  content: "\e074";
}
.icon-sortby:before {
  content: "\e075";
}
.icon-cart:before {
  content: "\e076";
}
.icon-mobiledevice:before {
  content: "\e077";
}
.icon-desktopdevice:before {
  content: "\e078";
}
.icon-magnifyglass:before {
  content: "\e079";
}
.icon-tabletdevice:before {
  content: "\e07a";
}
.icon-watchsdevice:before {
  content: "\e07b";
}
.icon-downside:before {
  content: "\e07c";
}
.icon-upside:before {
  content: "\e07d";
}
.icon-leftarrownew:before {
  content: "\e07e";
}
.icon-rightarrownew:before {
  content: "\e07f";
}
.icon-mobilemenu:before {
  content: "\e080";
}
.icon-landline:before {
  content: "\e081";
}
.icon-phonebook:before {
  content: "\e082";
}
.icon-questionhelp:before {
  content: "\e083";
}
.icon-checkshape:before {
  content: "\e084";
}
.icon-expandnew:before {
  content: "\e085";
}
.icon-hyperlink:before {
  content: "\e086";
}

$gothamboldwoff2:'/assets/fonts/Gotham-Bold.woff2';
$gothamboldwoff:'/assets/fonts/Gotham-Bold.woff';
$gothamboldttf:'/assets/fonts/Gotham-Bold.ttf';
$gothamboldsvg:'/assets/fonts/Gotham-Bold.svg#Gotham-Bold';


$gothambookwoff2:'/assets/fonts/Gotham-Book.woff2';
$gothambookwoff:'/assets/fonts/Gotham-Book.woff';
$gothambookttf:'/assets/fonts/Gotham-Book.ttf';
$gothambooksvg:'/assets/fonts/Gotham-Book.svg#Gotham-Book';

$gothammediumeot:'/assets/fonts/Gotham-Medium.eot';
$gothammediumeot2:'/assets/fonts/Gotham-Medium.eot?#iefix';
$gothammediumwoff2:'/assets/fonts/Gotham-Medium.woff2';
$gothammediumwoff:'/assets/fonts/Gotham-Medium.woff';
$gothammediumttf:'/assets/fonts/Gotham-Medium.ttf';
$gothammediumsvg:'/assets/fonts/Gotham-Medium.svg#Gotham-Medium';

$engineerwoff:'/assets/fonts/engineer.woff';
$engineerttf:'/assets/fonts/engineer.ttf';

@font-face {
  font-family: 'Gotham Bold';
  src: url($gothamboldwoff2) format('woff2'),
  url($gothamboldwoff) format('woff'),
  url($gothamboldttf) format('truetype'),
  url($gothamboldsvg) format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Gotham Book';
  src: url($gothambookwoff2) format('woff2'),
  url($gothambookwoff) format('woff'),
  url($gothambookttf) format('truetype'),
  url($gothambooksvg) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Gotham Medium';
  src: url($gothammediumeot);
  src: url($gothammediumeot2) format('embedded-opentype'),
  url($gothammediumwoff2) format('woff2'),
  url($gothammediumwoff) format('woff'),
  url($gothammediumttf) format('truetype'),
  url($gothammediumsvg) format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$headingfont:'Gotham Bold', Arial, Georgia, serif;
$subHeadingfont:'Gotham Medium', Arial, Georgia, serif;
$lightfont:'Gotham Book', Arial, Georgia, serif;
$normalfont:'Gotham Book', Arial, Georgia, serif;

/** Brand button **/
.brandFillBtn{background: #EB2F96; border-radius: 5px; font-size: 14px; line-height: 14px; color: #fff; border: 1px solid #EB2F96;}

/********************New UI 2.0 start********************/
@font-face {
  font-family: 'rubiklight';
  src: url('/assets/fonts/rubik-light-webfont.woff2') format('woff2'),
  url('/assets/fonts/rubik-light-webfont.woff') format('woff'),
  url('/assets/fonts/rubik-light-webfont.ttf') format('truetype'),
  url('/assets/fonts/rubik-light-webfont.svg#rubiklight') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rubikregular';
  src: url('/assets/fonts/rubik-regular-webfont.woff2') format('woff2'),
  url('/assets/fonts/rubik-regular-webfont.woff') format('woff'),
  url('/assets/fonts/rubik-regular-webfont.ttf') format('truetype'),
  url('/assets/fonts/rubik-regular-webfont.svg#rubikregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rubikmedium';
  src: url('/assets/fonts/rubik-medium-webfont.woff2') format('woff2'),
  url('/assets/fonts/rubik-medium-webfont.woff') format('woff'),
  url('/assets/fonts/rubik-medium-webfont.ttf') format('truetype'),
  url('/assets/fonts/rubik-medium-webfont.svg#rubikmedium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'rubikbold';
  src: url('/assets/fonts/rubik-bold-webfont.woff2') format('woff2'),
  url('/assets/fonts/rubik-bold-webfont.woff') format('woff'),
  url('/assets/fonts/rubik-bold-webfont.ttf') format('truetype'),
  url('/assets/fonts/rubik-bold-webfont.svg#rubikbold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$lightweight: 'rubiklight', Arial, Georgia, serif;
$normalweight: 'rubikregular', Arial, Georgia, serif;
$mediumweight: 'rubikmedium', Arial, Georgia, serif;
$boldweight: 'rubikbold', Arial, Georgia, serif;

$primecolor: #6C00EA;
$secondcolor: #00D659;
$darkcolor: #3C3E49;
$normalcolor: #9B9FB1;
$lightcolor:#83889E;
$errorcolor: #D50000;
$blackcolor : #000000;

$letterspacinig: -0.01em;

/********************New UI 2.0 close********************/

/* Buildcard™ Trademark Text */
sup.tm { top: 0; font-size: 60%; line-height: 0; vertical-align: super; font-weight: 600; text-transform: uppercase; font-family: $boldweight;
  &.light { font-weight: normal; font-family: $mediumweight; }
  &.alignTop { top: -.25em; }
}

@import "../stylesheets/fontFamily.scss";
@import "../stylesheets/_variables.scss";

.commonPopUp {opacity: 0; width: 100%; height: 100%; position: fixed; left: 0; top: 0; display:none; overflow-y: auto;
    
    .popOverlay {background: rgba(0, 0, 0, 0); width: 100%; height: 100%; z-index: 1;
      &.stayStick {position: fixed;}
    }
  
    .popHolder {
      background: #fff; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%) scale(0.8); z-index: 3; padding:40px; border-radius: 3px;
      width: 100%; max-height: 700px; @extend %customScrollY; overflow-y: auto;
      .closePopUp {position: absolute; cursor: pointer; z-index: 9; top: 15px; right: 15px; width: 32px; height: 32px; text-align: center;
        line-height: 12px; display: flex; justify-content: center; align-items: center; font-size: 16px; background: #fff; color: #3C3E49; border: 1px solid #DFE0E6; border-radius: 50px;
        &:hover {background: #F8F9FA;}
      }
  
      &.loginRegister {width: 100%; height: 100%; padding: 0; max-width: none; display: flex; border-radius: 0; transform: none; top: 0; left: 0; animation: none;}
    }

    &.active {opacity: 1; z-index: 9999999; display:block;
  
        .popOverlay {
          background: rgba(0, 0, 0, 0.14); animation: popupfade 0.4s cubic-bezier(.25,.8,.25,1);
          &.bgNone {background: none;}
          &.bgWhite {background: rgba(255,255,255,0.9);}
        }
        .popHolder:not(.loginRegister) {transform: translate(-50%, calc(-50% - .2px)) scale(1); animation: popupanimation 0.1s ease-in-out;}
    
        &.zIndex {z-index: 99999999;}
        &.noAnimation {
          .popOverlay, .popHolder {animation:none!important;}
        }
      }
  }

@-webkit-keyframes popupfade {
    0% {background: rgba(0, 0, 0, 0);}
    100% {background: rgba(0, 0, 0, 0.14);}
}

@keyframes popupfade {
    0% {background: rgba(0, 0, 0, 0);}
    100% {background: rgba(0, 0, 0, 0.14);}
}

@-webkit-keyframes popupanimation {
    0% {transform: translate(-50%, -50%) scale(0.8);}
    100% {transform: translate(-50%, -50%) scale(1);}
}

@keyframes popupanimation {
    0% {transform: translate(-50%, -50%) scale(0.8);}
    100% {transform: translate(-50%, -50%) scale(1);}
}


@media only screen and (max-width: 768px) {
    .commonPopUp {
        .popHolder {padding: 40px 24px; height:auto; max-height: 100%;
            .closePopUp {top:8px; right: 8px;}
        }
        &.active {
            .popOverlay, .popHolder {animation:none!important;}
        }
    }
}

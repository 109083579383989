/** custom scroll Y axis **/
%customScrollY{
  /* width */
  &::-webkit-scrollbar {width: 3px; border-radius:10px;}

  /* Track */
  &::-webkit-scrollbar-track {background: #fff !important;}

  /* Handle */
  &::-webkit-scrollbar-thumb {background: #e0e0e0; border-radius:10px;}

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {background: #e0e0e0; border-radius:10px;}
}
/** custom scroll X axis **/

%custonScrollX{
  &::-webkit-scrollbar { height: 4px; width: 50px;}
  &::-webkit-scrollbar-track { background: #fff; }
  &::-webkit-scrollbar-thumb { background: #ecebeb; border-radius: 5px; }
  &::-webkit-scrollbar-thumb:hover { background: #ecebeb; }
}

.myScrollbar, .innerScrollbar {
  >.ps {
    &.ps--active-y {
      >.ps__rail-y {width: 4px; background: none;
        .ps__thumb-y {width: 4px; right: 0;}

        &:hover {width: 4px;}
      }
    }

    &:hover:not(.ps--scrolling-y) {
      >.ps__rail-y {opacity: 0;}
    }
  }
}

%textEllipsis{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
